import {analyticsCode} from './data/global.yml';

console.log('THIS IS a CODE', analyticsCode);
/* eslint-disable */

window.ga=window.ga||function(){(ga.q=ga.q||[]).push(arguments)};ga.l=+new Date;
ga('create', analyticsCode, 'auto');
ga('send', 'pageview');

/* eslint-enable */
